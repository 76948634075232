import React from 'react'
import './style.scss'

export const HeaderComponents = () => {
  return (
    <header>
      <div className="header-wrapper">
        <div className="menu-block">
          <div className="menu-block__logo-block">
            <div className="menu-logo">
              <img style={{ width: 55 }} src="./assets/logo2.png" alt="logo" />
            </div>
            <div className="menu-logo__text">Быстрые займы онлайн</div>
          </div>
        </div>
      </div>
    </header>
  )
}
