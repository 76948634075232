import { sendMessageIsOpenOffer } from '../useRequest/tg'
import { requestTraffics } from '../useRequest/api'
export const requestUrl = ({
  link,
  name,
  realId,
  realLink,
}: {
  link: string
  name: string
  realId: number
  realLink: string
}) => {
  let params = new URL(link).searchParams
  requestTraffics(params.get('link'), params.get('operationHash')).then(
    (data: any) => {
      setTimeout(() => {
        window.open(data.data.link, '_blank')
      }, 100)
      sendMessageIsOpenOffer({
        offerName: name,
        offerId: String(realId),
        offerLink: realLink,
      })
    }
  )
}
