import axios from 'axios'
import env from '../env'

const baseURLNotToken = () =>
  axios.create({
    baseURL: env.baseUrl,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'Access-Control-Allow-Origin': '*',
      origin: '*',
      'Access-Control-Allow-Credentials': true,
    },
  })

export const requestLead = (partner: any, webmaster: any, transaction: any) => {
  return baseURLNotToken().post(`/api/leads`, {
    partner,
    webmaster,
    transaction,
  })
}

export const requestTraffics = (link: any, operationHash: any) => {
  return baseURLNotToken()
    .post(`/api/traffics`, {
      link,
      operationHash,
      leadId: Number(localStorage.getItem('leadId')),
    })
    .catch(() =>
      setTimeout(
        () =>
          baseURLNotToken().post(`/api/traffics`, {
            link,
            operationHash,
            leadId: Number(localStorage.getItem('leadId')),
          }),
        1000
      )
    )
}
