import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import './App.scss'
import { FooterComponents } from './footer'
import { HeaderComponents } from './header'
import { ShowcaseComponent } from './showcase'
import { requestLead } from './useRequest/api'

const URL_SITE = 'https://mforu.ru'

const App = () => {
  const [leadId, setLeadId] = React.useState(localStorage.getItem('leadId'))

  React.useEffect(() => {
    if (!localStorage.getItem('leadId')) {
      const url = new URL(document.URL)
      if (url.searchParams.get('utm_source') === 'yandex') {
        requestLead(
          url.searchParams.get('utm_source'),
          'mforu.ru - ' + url.searchParams.get('utm_term'),
          null
        )
          .then(data => {
            window.location.href = URL_SITE
            setLeadId(data.data.id)
            localStorage.setItem('leadId', data.data.id)
          })
          .catch(() => {
            localStorage.setItem('leadId', '1')
            setLeadId('1')
          })
      } else {
        requestLead(null, null, null)
          .then(data => {
            localStorage.setItem('leadId', data.data.id)
            setLeadId('1')
          })
          .catch(() => {
            localStorage.setItem('leadId', '1')
            setLeadId('1')
          })
      }
    }
  }, [])
  if (!leadId) {
    return <div></div>
  }
  return (
    <React.Fragment>
      <BrowserRouter>
        <img
          src={'/assets/tg.png'}
          onClick={() => {
            //@ts-ignore
            ym(92430193, 'reachGoal', 'telegram')
            return window.open(
              'https://t.me/test_Kristina_22_bot?start=welcome'
            )
          }}
          id={'tg_icon'}
          className={'tg-icon'}
        />
        <HeaderComponents />
        <Routes>
          <Route path="/" element={<ShowcaseComponent leadId={leadId} />} />
        </Routes>
        <FooterComponents />
      </BrowserRouter>
    </React.Fragment>
  )
}

export default App
