import axios from 'axios'

type Props = {
  phone: string
  email: string
  lastName: string
  firstName: string
  middleName?: string
  text: string
}

type PropsShowcase = {
  offerName: string
  offerId: string
  offerLink: string
}

export const sendMessageIsOpenOffer = ({
  offerName,
  offerId,
  offerLink,
}: PropsShowcase) => {
  const leadId = localStorage.getItem('leadId')
  axios(
    `https://api.telegram.org/bot5914549575:AAHYpdFcWzhjVkPAQpipd5wOfIS80C8a9ew/sendMessage?chat_id=-1001741697592&parse_mode=html&text= 🆕🆕🆕Новый переход по витрине🆕🆕🆕 
    %0A leadID: ${leadId} (mforu.ru)
    %0A наименование: ${offerName} 
    %0A offerID: ${offerId} 
    %0A ссылка реферальная:${offerLink}`
  )
}
