import React, { useCallback } from 'react'
import env from '../env'
import axios from 'axios'

type Props = { url: string; method?: string; params?: any; statusUrl?: boolean }

const baseURL = () =>
  axios.create({
    baseURL: env.baseUrl,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'Access-Control-Allow-Origin': '*',
      origin: '*',
      'Access-Control-Allow-Credentials': true,
    },
  })

export const useRequestAxios = ({ url, method = 'GET', params }: Props) => {
  const [data, setData] = React.useState(null)
  const [loader, setLoader] = React.useState(false)
  const [errors, setError] = React.useState(null)

  React.useEffect(() => {
    search(url)
  }, [])

  const clearData = useCallback(() => {
    setData(null)
  }, [])

  const search = useCallback(
    (url: string) => {
      setLoader(true)
      setError(null)
      if (method === 'GET') {
        baseURL()
          .get('/api/' + url)
          .then(response => {
            setData(response.data)
          })
          .catch(error => {
            setError(error)
          })
          .finally(() => setLoader(false))
      }
    },
    [url]
  )

  return { data, search, loader, clearData, errors }
}
