import React from 'react'
import './style.scss'

export const FooterComponents = () => {
  return (
    <footer>
      <div className="footer-wrapper main-block">
        <div className="footer-block">
          <div className="footer-content-left">
            <div className="footer-block__final-text">
              mforu.ru собирает выгодные предложения для микрозаймов. Сайт
              предоставляет данные исключительно информационного характера.
              Здесь же вы можете ознакомиться с удобными предложениями с выводом
              прямо на карту, онлайн, круглосуточно и без отказа!{' '}
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}
