import React from 'react'
import './style.scss'
import { CardComponent } from './components/card'
import { useRequestAxios } from '../useRequest/use-request-axios'
import { Offer, Showcase } from './type'

export const ShowcaseComponent = (props: { leadId?: any }) => {
  const url = `showcases?type=${10}&leadId=${
    props?.leadId || localStorage.getItem('leadId')
  }`

  const { data }: { data: any | null; errors: any } = useRequestAxios({
    url: url,
  })

  console.log('hello', data)
  if (data === null) {
    return <div></div>
  }

  return (
    <div className="main-block">
      <div className="slider-offers">
        <div className="slider-offers__title slider-offers__title-desktop">
          Рейтинг срочных займов на сегодня
        </div>
      </div>
      <div className={'list-of-loans'}>
        <div className={'list-of-loans__flex-start'}>
          {data?.offers?.map((item: Offer) => (
            <CardComponent key={item.id} {...item} />
          ))}
        </div>
      </div>
    </div>
  )
}
