import React from 'react'
import { requestUrl } from '../requestUrl'
import { Offer } from '../type'

export const CardComponent = (props: Offer) => {
  return (
    <div className="list-of-loans__loan">
      <div className="list-of-loans__content">
        <div className="list-of-loans__head-container">
          <div className="list-of-loans__logo">
            <div
              onClick={() =>
                requestUrl({
                  link: props.link,
                  name: props.offer.title,
                  realId: props.id,
                  realLink: props.offer.realLink || props.link,
                })
              }
              className="action-link"
            >
              <img src={'https://moneytka.ru/' + props.offer.image.file.url} />
            </div>
          </div>
          <div className="list-of-loans__fire">
            <img src="https://assets.smartzaim.online/images/fire.png" />
          </div>
        </div>
        <div className="list-of-loans__info_container">
          <div className="list-of-loans__info_wrapper">
            <div className="list-of-loans__info">
              <div className="list-of-loans__info-title">До</div>
              <div className="list-of-loans__info-count">
                {props.offer.maxPrice} ₽{' '}
              </div>
            </div>
            <div className="list-of-loans__info-terms">
              <div className="list-of-loans__term-left">
                <div className="list-of-loans__term-title">срок (дней)</div>
                <div className="list-of-loans__term-description">
                  {props.offer.minDay} - {props.offer.maxDay}{' '}
                </div>
              </div>
              <div className="list-of-loans__term-right">
                <div className="list-of-loans__term-title">Процент</div>
                <div className="list-of-loans__term-description">
                  от {props.offer.maxPercent} %
                </div>
              </div>
            </div>
          </div>
          <div
            onClick={() =>
              requestUrl({
                link: props.link,
                name: props.offer.title,
                realId: props.id,
                realLink: props.offer.realLink || props.link,
              })
            }
            className="list-of-loans__title-box"
          >
            <div className="list-of-loans__button action-link">
              Получить деньги
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
